import { ComponentType, useEffect, useState } from "react"
import debounce from "lodash/debounce"
import { FrameProps } from "framer"

export function withGridLine(Component): ComponentType {
    return (props: FrameProps) => {
        return (
            <Component
                {...props}
                style={{
                    ...props.style,
                    transformOrigin: "top left",
                }}
                animate={{ scaleX: [0, 1] }}
                transition={{ duration: 2, ease: [0.075, 0.82, 0.165, 1] }}
            />
        )
    }
}

export function withGridWidth(Component): ComponentType {
    return (props: FrameProps) => {
        const DEFAULT_WIDTH = 25
        const [width, setWidth] = useState(DEFAULT_WIDTH)

        useEffect(() => {
            const style = document.createElement("style")

            style.textContent = `
                .grid-align:nth-child(2n) {
                    justify-content: end;
                }

                .grid-align:nth-child(3n) {
                    justify-content: center;
                }
            `

            document.head.appendChild(style)

            return () => {
                document.head.removeChild(style)
            }
        }, [])

        useEffect(() => {
            const onResize = () => {
                if (window.matchMedia("(max-width: 609px)").matches) {
                    setWidth(100)
                } else if (window.matchMedia("(max-width: 1100px)").matches) {
                    setWidth(50)
                } else {
                    setWidth(DEFAULT_WIDTH)
                }
            }

            const debouncedResize = debounce(onResize, 200)
            window.addEventListener("resize", debouncedResize)
            onResize()

            return () => {
                window.removeEventListener("resize", debouncedResize)
            }
        }, [])

        return (
            <Component
                {...props}
                className={`${props.className} grid-align`}
                style={{
                    ...props.style,
                    width: `${width}vw`,
                }}
            />
        )
    }
}

export function withGridWidthImage(Component): ComponentType {
    return (props: FrameProps) => {
        return (
            <Component
                {...props}
                style={{
                    ...props.style,
                    width: "75vw",
                }}
            />
        )
    }
}
